.dashboard {
  .heading {
    color: #5EAB07;
    font-weight: 700;
    font-size: 18px;
  }
  .headings{
    color: #264503;
    font-weight: 700;
    font-size: 18px;
  }
  .filterselect {
    padding: 5px 20px;
    background-color: #5eab07;
    border: 1px solid #0000001f;
    border-radius: 6px;
    color: white;
    option {
      background-color: white;
      color: black;
    }
  }
  .firstcat {
    .firstcatCont{
      width: 20%;
      @media(max-width:1400px){
        width: 25%;
      }
      @media(max-width:1200px){
        width: 33%;
      }
      @media(max-width:900px){
        width: 50%;
      }
    }
    .cnt1 {
      height: 100px;
      border-radius: 10px;
      padding: 17px 20px;
      display: flex;
      align-items: center;
      // justify-content: space-between;
      background-color: white;
      box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);
      .hewecontimg {
        height: 18px;
        width: 18px;
      }
      .seprate {
        height: 20px;
        width: 1px;
        background-color: #0000001f;
      }
      h4 {
        color: #2645035e;
        font-size: 16px;
        font-weight: 600;
      }
      h3 {
        color: #2d3748;
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 0;
      }
      p {
        font-size: 14px;
        font-weight: normal;
        color: black;
      }
    }
  }

  .secondcat {
    .pergraph{
      height: 600px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .form-check-input:checked{
      background-color: #264503;
      border-color: #264503;
    }
    .graph1 {
      height: 100%;
      // height: 500px;
      border-radius: 10px;
      margin-bottom: 20px;
      padding: 20px 20px;
      background-color: white;
      box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);
    }
    .stat {
      border-radius: 10px;
      padding: 20px 20px;
      background-color: white;
      box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);
      .cont {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 20px;
        border: 1px solid #88ae4024;
        h3 {
          font-size: 18px;
          margin-bottom: 0;
        }
        .cycle {
          font-weight: 700;
          color: #375dfb;
        }
        .walk {
          font-weight: 700;
          color: #e06eb5;
        }
      }
    }
    .performance {
      border-radius: 15px;
      padding: 15px;
      height: 600px;
      background-image: url("../../images/hewe/perbg.png");
      background-repeat: no-repeat;
      background-size: cover;
      .heading {
        color: #5eab07;
      }
      .entry {
        margin: 10px 0px;
        border-radius: 6px;
        border: 2px solid #5eab07;
        padding: 10px 20px;
        color: white;
        h4 {
          font-size: 20px;
          font-weight: 600;
          color: #fbbe1d;
          margin-bottom: 0;
        }
        h5 {
          font-weight: 400;
          font-size: 16px;
          margin-bottom: 0;
          color: white;
        }
        .levelImg {
          height: 40px;
          width: 35px;
          position: relative;
        }
        .levels {
          font-weight: 700;
          font-size: 14px;
          color: #5c0196;
          position: absolute;
          top: 3px;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .thirdcat {
    .walking{
      height: 10px;
      width: 10px;
      // border-radius: 50%;
      background-color: rgba(144, 238, 144);
      margin-right: 7px;
    }
   .cycling{
    margin-right: 7px;
      height: 10px;
      width: 10px;
      // border-radius: 50%;
      background-color: rgba(0, 128, 0);
    }
    
    .distribute,.cw-average {
      border-radius: 10px;
      padding: 20px 20px;
      background-color: white;
      box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);
      .table-container{
        margin-top: 22px;
        border-radius: 10px; 
        overflow-x: scroll; 
        border-top: 2px solid #d9e3d9; 
        border-left: 2px solid #d9e3d9; 
        border-right: 2px solid #d9e3d9; 
          table {
            width: 100%;
            border-radius: 10px;
            th,
            td {
              white-space: nowrap;
              padding: 15px 12px;
              border-bottom: 2px solid #d9e3d9;
            }
          }
      }
    }
  }
}


.performance{
  padding: 20px 20px;
  .MuiPaginationItem-page{
    border-radius: 4px;
    height: 30px;
    width: 30px;
    background-color: white !important;
    color: rgb(0, 0, 0);
    font-weight: 400;
  }
  .Mui-selected{
    border-radius: 4px;
    height: 30px;
    width: 30px;
    background-color: white !important;
    color:#5eab07;
    font-weight: 900;
  }
  .MuiPaginationItem-ellipsis{
    color: white;
  }

}