.customButton {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 1rem;
  gap: 0.5rem;
  padding: 8px 0rem;
}
.nav-profile-container {
  position: absolute;
  top: 70px;
  right: 5px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.pr-devider {
  border-bottom: 1px solid #eaeaea;
}
.nav-profile-section {
  display: flex;
  flex-direction: column;
  width: 300px;
}
.logo-div {
  height: 35px;
  width: 35px;
  background-color: #21afe6;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.logoImage {
  width: 12rem;
}
.logo-name {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #21afe6;
}
.profile-image {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
.profile-name {
  font-size: 13px;
  text-transform: capitalize;
  color: white;
  font-family: "Poppins", sans-serif;
  line-height: 19px;
  letter-spacing: 0em;
}
.nav-btn-logo {
  padding: 0px !important;
  margin-right: 8px;
}
.logout-header {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: #001050;
  padding-top: 2rem;
}
.are-you-sure {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  color: #7f909c;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.8rem;
}
.logout-button-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}
.cancel-button {
  border-radius: 4px;
  border: 1px solid var(--light-Stock, #D9E3D9);
  padding: 0.6rem 3rem;
  cursor: pointer;
}
.logout-button {
  color: white;
  border-radius: 4px;
  background: var(--Red2, #F74747);
  padding: 0.6rem 3rem;
  cursor: pointer;
}
