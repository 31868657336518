.ticketSelect {
   margin: auto;
    padding: 0px 4px;
    width: 150px;
    border-radius: 4px;
    color: black;
    text-transform: capitalize;
    font-weight: 600;
    .css-b62m3t-container{
        margin: auto;
    }

    .css-13cymwt-control{
        background-color: transparent;
        border: 0;
    }
    .css-13cymwt-contro:focus{
        background-color: transparent;
    }
    
}
.ticketOpen{
    border: 1px solid #0022ffc2;
    background-color: #9FB7FF99;
}
.ticketReopen{
    border: 1px solid #55ff0040;
    background-color: #65AE4030;
}
.ticketClose{
    border: 1px solid #FF414140;
    background-color: #FFE2E2;
}
.ticketReview{
    border: 1px solid #ffba0c;
    background-color: #FFEEC2;
}

