.addbtn {
  border-radius: 4px;
  background-color: #88ae40;
  border: none;
  color: white;
  padding: 12px 20px;
}

.b1 {
  border: 1px solid black;
}

.historybtn {
  text-transform: capitalize;
  cursor: pointer;
  margin: auto;
  width: fit-content;
  padding: 10px 16px;
  border: 1px solid #88ae40;
  background-color: #bae26c54;
  border-radius: 4px;
}

.historystat {
  h5 {
    color: #264503;
    font-size: 20px;
    @media(max-width:1500px){
      font-size: 16px !important;
      font-weight: 400;
    }
    strong {
      color: #264503;
      @media(max-width:1500px){
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
}

.vocherform {
  background-color: white;
  margin: 30px;
  padding: 30px;

  .form-control {
    border: 1px solid #4b733333;
  }

  .imgcont {
    background-color: #e0fcad1f;
    position: relative;
    border: 1px solid #88ae40;
    height: 128px;
    width: 128px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    overflow: hidden;

    .brandimg {
      width: 128px;
      height: 128px;
    }
 .bannerimg{
      height: 128px;
      width: 100%;
    }
    .plusIcon {
      cursor: pointer;
      background-color: #264503;
      color: white;
      height: 20px;
      width: 20px;
      font-size: 15px;
    }

    .edit {
      // background-color: white;
      font-size: 24px;
      color: #264503;
      position: absolute;
      bottom: 0;
      right: 0px;
      cursor: pointer;
    }
  }
.imgcont2{
  height: 128px;
  width: 220px !important;
}
  .savebtn {
    background-color: #264503;
    color: white;
    padding: 7px 30px;
    border-radius: 7px;
    border: none;
  }

  li.inptCont {
    background-color: #f8f8f8;
    color: rgb(0, 0, 0, 0.7);
  }

  .btndisabled {
    background-color: #264503;
    opacity: 0.5;
    color: white;
    padding: 7px 30px;
    border-radius: 7px;
    border: none;
  }

  label {
    color: #264503;
    font-weight: 500;
    margin-bottom: 10px;
    text-transform: capitalize;
    position: relative;

    &::after {
      content: "*";
      color: red;
      position: absolute;
      right: -10px;
      top: 0;
    }
  }

  .rewardsize {
    font-weight: 400;
    color: #939393;
    font-size: 12px;
  }

  .inptCont {
    padding: 6px 12px;
    border: 1px solid #4b733333;
    border-radius: 6px;
    margin-bottom: 26px;

    input {
      border: none;
      width: 100%;

      &::placeholder {
        color: #00000027;
      }

      &:is(:hover, :focus) {
        border: none;
        outline: none;
      }
    }

    textarea {
      font-style: italic;
      height: 120px;
      border: none;
      width: 100%;

      &::placeholder {
        color: #00000027;
      }

      &:is(:hover, :focus) {
        border: none;
        outline: none;
      }
    }
  }
}

.actionicon {
  color: #88ae40;
  font-size: 18px;
  border-radius: 4px;
}

.deleteicon {
  color: #6a7dff;
  font-size: 18px;
  border-radius: 4px;
}

.css-1jaw3da {
  width: 50px !important;
}

.activebg {
  margin: auto;
  width: 100px;
  background-color: #d1ead2;
  color: #34b83b;
  font-size: 12px;
  padding: 4px 26px;
  border-radius: 20px;
  cursor: pointer;
}

.inactivebg {
  margin: auto;
  width: 100px;
  background-color: #e3505070;
  color: #d63a2c;
  font-size: 12px;
  padding: 4px 26px;
  border-radius: 20px;
  cursor: pointer;
}

.referralcont {
  h4 {
    font-size: 20px;
  }
}
