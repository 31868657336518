.notificationCont{
.notifcont{
    background-color: white;
    width: 100%;
    border-radius: 6px;
    padding: 12px;
    box-shadow: 0px 4px 12px rgba(55, 93, 251, 0.12);
    border: 1px solid rgba(136, 174, 64, 0.15);
    h6{
        color: #264503;
    }
   
    .dropSelect{
        height: 48px;
        width: 311px;
        border-radius: 4px;
        border: 1px solid #4B733333;
        justify-content: space-between;
        display: flex;
        align-items: center;
        position: relative;
        h3{
            padding-left: 16px;
            font-size: 18px;
            font-weight: 400;
            margin-bottom: 0;
        }
       
        .dropkey{
            width: 31px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #4B73331A;
        }

    }
    .notifInput{
        padding: 0px 12px;
        color: 18px;
        height: 52px;
        border-radius: 4px;
        border: 1px solid #6ECBE08C;
    }
    .textareanot{
        padding: 12px;
        width: 100%;
        height:150px;
        border-radius: 4px;
        border: 1px solid #6ECBE08C;
    }
    .dropData{
        z-index: 9;
        position: absolute;
        width: 310px;
        background-color: white;
        padding: 20px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    .datetime{
        border-radius: 4px;
        border: 1px solid #4B733333;
        padding: 20px;
        height:50px;
    }

}


.filterBody{
    right: 0;
    top: 60px;
    left:300px;
    position: absolute;
    padding: 20px;
    width: fit-content;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    @media(max-width:620px){
        left: 250px;
    }
    @media(max-width:576px){
        top: 110px;
        left: 0;
    }
    h6{
        font-size: 16px;
        color: #24363A;
    }
}

.calender{
    padding: 4px;
    border-radius: 4px;
    width: 250px;
    height: 40px;
    border: 1px solid #4B733333;
}

}

.General,.Warning,.Schedule{
    display: flex;
    margin: auto;
    align-items: center;
    justify-content: center;
     background-color: rgba(94, 171, 7, 0.22);
     color: black;
     width: 77px;
     height: 29px;
     border-radius: 4px;
     border: 0;
}
.Warning{
    background-color:#f747472f;
}
.Schedule{
    background-color: rgba(106, 125, 255, 0.22);
}

input[type="radio"] {
    accent-color: #089a34; /* Change to your desired color */
  }