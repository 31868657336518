@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Knewave&display=swap');

@font-face {
  font-family: times;
  src: url("./assets/font/times.ttf");
}

.ff-time {
  font-family: 'Times New Roman', Times, serif;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

ul,
p {
  margin: 0;
  padding: 0;
}


.css-1480iag-MuiInputBase-root-MuiInput-root:before {
  border-bottom: none !important;
  transition: none !important;
}

.css-1jaw3da {
  width: 50px !important;
}

.font-italic {
  font-style: italic;
}

.css-14el2xx-placeholder {
  color: hsl(0, 0%, 50%);
  grid-area: 1/1/2/3;
  margin-left: 2px;
  margin-right: 2px;
  margin-bottom: 9px;
  box-sizing: border-box;
  font-size: 15.9px;
}

.flex-col-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.word-break-all {
  word-break: break-all;
}

.fs-11 {
  font-size: 11px;
}

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.fs-15 {
  font-size: 15px;
}

.fs-16 {
  font-size: 16px;
}

.fs-17 {
  font-size: 17px;
}

.fs-20 {
  font-size: 20px;
}

.fs-22 {
  font-size: 22px;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.flex-items-center {
  display: flex;
  align-items: center;
}

.fw-700 {
  font-weight: 700;
}

.font-2 {
  color: #00000080;
}

.w-fit-content {
  width: fit-content;
}

.font-g1 {
  color: #2C4E05;
}
.font-b2{
  color: #6A7DFF;
}
.font-g2 {
  color: #88AE40;
}

.font-dark-green {
  color: #264503;
}

.font-yellow {
  color: #FFD742;
}

.font-g1 {
  color: #34B83B;
}

.bg-g1 {
  background-color: #88ae40;
}

.font-b1 {
  color: #035F8A;
}

.font-r1 {
  color: #E35050;
}

.font-#F8F8F8 {
  color: #F8F8F8;
}

.pointer {
  cursor: pointer;
}

.text-24363a {
  color: #24363a;
}

.tu {
  /* text-decoration: underline; */
  cursor: pointer;
}

.bg88AE4038 {
  background-color: #88AE4038;
}

.bg5B009738 {
  background-color: #5B009738;
}

.error {
  font-size: 12px;
  color: red;
}

.deletebtn {
  padding: 7px 20px;
  border-radius: 4px;
  background-color: #E35050;
  color: white;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.flex-acenter-jcenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-acenter-jbetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn1 {
  padding: 0.8rem 2rem;
  border-radius: 4px;
  background: var(--Dark-Green-font, #264503);
  color: white;
  cursor: pointer;
}

.signouttbn {
  border: 0;
  background-color: none;
  background: none;
}

.underline {
  text-decoration: underline;
}

.savebtn {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #264503;
  border: 1px solid #264503;
  border-radius: 4px;
  padding: 10px 20px;
  color: white;
}

.selectBtn {
  cursor: pointer;
  border: 2px solid rgba(127, 183, 104, 0.2196078431);
  margin-right: 12px;
  border-radius: 4px;
  padding: 6px 20px;
  color: rgb(0, 0, 0);
  color: gray;
  font-style: italic;

}

.canclebtn,.cancelbtn{
  height: 40px;
  cursor: pointer;
  background-color: #ffffff;
  border: 1px solid #26344E38;
  border-radius: 4px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  color: #264503;
}

.form-controlcont {
  display: flex;
  align-items: center;
  padding: 1px 10px;
  border-radius: 5px;
  border: 1px solid rgba(75, 115, 51, 0.20);
  color: white;

  i {
    color: Green;
  }

  .white-space-nowrap {
    white-space: nowrap;
  }

  input,
  select {
    width: 100%;
    color: rgb(173, 166, 166);
    background-color: transparent;
    border: 0px;

    &:focus {
      background-color: none;
      border: none;
      outline: none;
    }
  }
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #23232329;
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: none;
  outline: 0;
  box-shadow: none;
}

.makeStyles-tableMainContainer-2::-webkit-scrollbar {
  width: 6px;
  height: 4px;
}

.hewe-cont {
  background: #F7F7F7;
  margin-left: 280px;
  padding: 20px 16px;
  min-height: calc(100vh - 80px);

  @media(max-width:780px) {
    margin-left: 0;
  }
}

.logout-btn {
  cursor: pointer;
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 20px 20px;
  border-top: 1px solid #D9E3D9;

  span {
    margin-left: 20px;
    color: #D9E3D9;
  }
}


.react-tel-input .country-list {
  background-color: rgb(250, 248, 248) !important;
}

input[type="checkbox"] {
  accent-color: #88AE40;
  color: white;
  width: 16px;
  height: 16px;
}



::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ffffff;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #264503;
  border-radius: 10px;
  cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #414d2c;
}

.react-tel-input .country-list {
  max-height: 250px !important;
  overflow-x: hidden;
}

.phone-input {
  .form-control {
    padding-right: 0;
  }
}

.react-tel-input .form-control {
  font-size: 1rem !important;
  font-weight: 500;
}

.list-style-none {
  list-style: none;
}

.MuiOutlinedInput-input {
  padding: 5.5px 14px !important;
  font-weight: 600;
}

.white-space-nowrap {
  white-space: nowrap;
}

.back-color {
  color: #264503;
}

.text-b1 {
  color: #375DFB;
}

.text-p1 {
  color: #E06EB5;
}

.text-y1 {
  color: #E6B605;
}

.Archived {
  margin: 10px 0px;
  text-transform: uppercase;
  background-color: white;
  padding: 8px 16px;
  border: 1px solid #26450326;
  border-radius: 4px;
  color: #264503;
  font-weight: 500;
  margin-right: 12px;
}

.ArchivedActivebtn {
  text-transform: uppercase;
  border: 1px solid #264503;
  background-color: #264503;
  color: white;
  padding: 8px 16px;
  font-weight: 500;
  margin-right: 12px;
  border-radius: 4px;

}

.ExportXls {
  cursor: pointer;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  background-color: #88AE40;

}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-se {
  min-height: 2.4em !important;
}

.backtohome {
  cursor: pointer;
  margin: 16px 20px;
  border-radius: 4px;
  font-size: 16px;
  padding: 10px 16px;
  border: 1px solid #FFFFFFA8;
  color: white;
}


.dott {
  background-color: #264503;
  height: 12px;
  width: 12px;
  border-radius: 50%;
}

.main-heading {
  font-size: 22px;
  font-weight: 500;
  color: #264503
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  font-family: 'Poppins' !important;
}

.admintoggle {
  color: #88AE40;

  svg {
    color: #88AE40 !important;
  }

  &:hover {
    color: #264523;

    & svg {
      color: #264523 !important;
    }
  }
}

.jodit-workplace {
  height: 55vh !important;
}

.toogleHover {
  border-radius: 4px;
  background-color: white;
  position: absolute;
  left: 50px;
  z-index: 9;
  top: -20px;
  padding: 5px;
  box-shadow: 5px 4px 4px #0000001F;
}

.toogleHoverViewcont {
  position: relative;
  cursor: pointer;
}

.toogleViewData {
  position: absolute;
  left: 50px;
  z-index: 9;
  background-color: #fff;
  text-align: start;
  box-shadow: 0 4px 4px #0000001F;
  padding: 10px;
  top: 0px
}

.toogleHoverView:hover .toogleViewData {
  display: block;
}

.border-1 {
  border: 1px solid black;
}

.hovertoggle:hover {
  background-color: #88AE4038;
  font-weight: 600;
  border-radius: 4px;
}

.truncate {
  display: block;
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.css-1sazv7p-MuiStack-root {
  padding: 12px 0px;
}

.realMoney {
  padding: 8px 7px;
  background-color: #7FB76852;
  color: black;
  border-radius: 4px;
}

.hewecoin {
  padding: 8px 7px;
  background-color: #FBBE1D8F;
  color: black;
  border-radius: 4px;
}

.emptyTable{
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 4px gray;
  font-size: 22px;
  color: black;
  font-weight: 600;
  border-radius: 8px;
}

.react-tel-input .form-control {
  padding-left: 39px !important;
}

.nowrap {
  white-space: nowrap;
}

.awardImg {
  position: relative;


  img {
    object-fit: contain;
    width: 40px;
    height: 40px;
  }

  .level {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 5px;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 10px;
    font-weight: 600;
  }
}


.searchbars{
  height: 35px !important;
  box-shadow: none !important;
  border: 1px solid #00000045;
}
.searchbars1{
  height: 40px !important;
  box-shadow: none !important;
  border: 1px solid #00000045;
}

.form-control{
  padding: .47rem .75rem !important;
}

.css-tj5bde-Svg{
  color: black !important;
}
/* .css-1xc3v61-indicatorContainer,.css-151sz6c-indicatorContainer {
  background: white;
  border-top-right-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
} */
.css-1u9des2-indicatorSeparator{
  background-color: none !important;
}
.ticketSelect{
  padding: 0 !important;
}

.w-120{
  width: 150px;
}

.overlayleading{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}


.op-8{
  opacity: 80%;
}
.op-10{
  opacity: 100%;
}

.ratesTable{
  width: 600px;
  @media(max-width:992px){
    width: 100%;
  }
}

.link{
  cursor: pointer;
  text-decoration: underline;
  color: blue;
}

.zm1{
  z-index: -1;
} 

.unselectable {
  user-select: none; /* Standard */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.filterBody{
  z-index: 99;
}

.MuiDialogContent-root .css-qr46ko{
  max-height: 150px !important;
}