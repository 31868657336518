.referralcont {
  min-height: 100%;

  .dot {
    height: 16px;
    width: 16px;
    background-color: #264503;
    border-radius: 50%;
  }

  .desc {
    width: fit-content;
    border: 1px solid #264503;
    border-radius: 5px;
    padding: 10px 16px;
  }
  .notes {
    background-color: #ffd7453d;
    width: fit-content;
    border: 1px solid #264503;
    border-radius: 5px;
    padding: 10px 16px;
    p {
      font-style: italic;
    }
  }

  h2 {
    font-size: 24px;
  }

  .perrefer {
    font-size: 18px;
  }

  .rewardcardcont {
    margin: 20px 0px 10px 0px;

    .cardcont {
      cursor: pointer;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      border: 1px solid #0000001c;
      border-radius: 8px;
      background-color: #efefef;

      @media (max-width: 1200px) {
        height: 70px;
      }

      h4 {
        color: inherit;
        margin-bottom: 0;
        font-size: 16px;

        @media (max-width: 1200px) {
          font-size: 16px;
        }
      }
    }
  }

  .refcont {
    margin-top: 20px;
    padding: 16px 20px;
    border: none;
    border-radius: 10px;
    background-color: white;
    h3 {
      font-size: 16px;
    }
    .pagerespo {
      width: 75%;
      @media (max-width: 992px) {
        width: 100%;
      }
    }

    .card-type {
      font-weight: 600;
      text-align: center;
      background-color: #88ae4008;
      border: 1px solid #5eab0726;
      border-radius: 4px;
      padding: 9px 0px;
    }
    label {
      color: #264503;
      font-weight: 600;
    }

    input {
      border: 1px solid #264503 !important;
      border-radius: 4px;
      padding: 8px 16px;
    }

    button {
      background-color: #264503;
      padding: 12px 45px;
      border: none;
      color: white;
      border-radius: 4px;
    }
  }
}
