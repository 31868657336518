.tracinpt{
  width: 200px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid rgba(217, 227, 217, 1);
  padding: 7px;
}
.claimManagement {
  .rewardcardcont {
    margin: 20px 0px 10px 0px;

    .cardcont {
      cursor: pointer;
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      border: 1px solid #0000001c;
      border-radius: 8px;
      background-color: #efefef;

      @media (max-width: 1200px) {
        height: 70px;
      }

      h4 {
        color: inherit;
        margin-bottom: 0;
        font-size: 16px;

        @media (max-width: 1200px) {
          font-size: 16px;
        }
      }
    }
  }
  .transfered {
    padding: 7px 10px;
    background-color: rgba(235, 255, 238, 1);
    border: 1px solid rgba(17, 153, 38, 0.25);
    border-radius: 4px;
  }
  .cusSelect {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(255, 241, 191, 0.38);
    border: 1px solid rgba(251, 190, 29, 0.54);
    border-radius: 4px;
    width: 150px;
    height: 40px;
    padding-left: 10px;
    .cusdrop {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      width: 40px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
    }
  }
}
.cusOption {
  position: absolute;
  left: 65px;
  .item {
    padding: 7px;
    background-color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  }
  .css-zaiplu-menu {
    z-index: 999;
  }
}
.detailspage {
  .cardcont {
    height: 320px;
    border-radius: 10px;
    background-color: white;
    filter: drop-shadow(0, 2px, 12px, rgba(16, 24, 40, 0.05));
    box-shadow: 0, 2px, 12px, rgba(16, 24, 40, 0.05);
  }
  .line{
    width: 100%;
    height: 2px;
    background-color: #efefef;
  }
  .link{
    color: blue;
    text-decoration: underline;
  }
  .imgcont {
    width: 141px;
    height: 117px;
  }
  .rewardEarn {
    width: fit-content;
    background-color: rgba(255, 241, 190, 1);
    padding: 4px 10px;
  }
  .trackingId {
    font-size: 20px;
    color: rgba(127, 183, 104, 1);
  }
  .p2 {
    color: rgba(75, 85, 88, 1);
  }
}

.transfered {
  width: fit-content;
  padding: 7px 33px !important;
  background-color: rgba(235, 255, 238, 1);
  border: 1px solid rgba(17, 153, 38, 0.25);
  border-radius: 4px;
}