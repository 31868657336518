.auth {
  background-color: rgb(247, 247, 247);
  width: 100%;
  height: 100vh;
  z-index: 999;
  .login {
    background-image: url(../../images/hewe/loginbanner.png);
    background-position: center;
    background-size: cover;
    height: 100vh;
    width: 100%;
    @media (max-width: 768px) {
      padding: 0px 26px;
    }
    .logincontainer {
      padding: 40px;
      border-radius: 20px;
      margin-right: 100px;
      width: 474px;
      background-color: rgba(255, 255, 255, 0.1);

      @media (max-width: 768px) {
        margin-right: 0;
        margin: auto;
      }
      @media (max-width: 576px) {
        width: 100%;
        padding: 20px;
      }
      .heading {
        margin-top: 26px;
        color: #fff;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 19.313px;
        text-transform: uppercase;
        span {
          padding-left: 8px;
          color: #decc89;
          font-size: 28px;
          font-weight: 275;
          letter-spacing: 5.04px;
        }
      }
      .loginheading {
        margin-top: 50px;
        div {
          width: 100%;
          height: 2px;
          background-color: #d9d9d9;
          border-radius: 5px;
        }
        h4 {
          padding: 0px 12px;
          white-space: nowrap;
          color: white;
        }
      }
      .form {
        label {
          display: flex;
          justify-content: start;
          color: white;
          text-transform: capitalize;
        }
        .error {
          color: #f74747;
          display: flex;
          justify-content: start;
        }
        .form-controlcont {
          padding: 1px 10px;
          border-radius: 5px;
          border: 2px solid #4b7333;
          color: white;
          width: 100%;
          @media (max-width: 1400px) {
          }
          i {
            color: Green;
          }
          input {
            color: white;
            background-color: transparent;
            border: 0px;
          }
        }
        button {
          color: white;
          margin-top: 30px;
          border: 0;
          display: flex;
          width: 394px;
          height: 50px;
          padding: 10px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          flex-shrink: 0;
          border-radius: 4px;
          background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.4) 0%,
              rgba(0, 0, 0, 0.4) 100%
            ),
            linear-gradient(90deg, #7fb768 0%, #ffd742 100%);
          box-shadow: 0px 2px 9.9px 0px rgba(0, 0, 0, 0.1);
          @media (max-width: 780px) {
            width: 100%;
          }
        }
      }
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #23232329;
}
