.addSubAdmin {
    .cont {
        background-color: white;
        border: 1px solid #88AE4070;
        width: 100%;
        border-radius: 8px;
        box-sizing: 0px 4px 4px #26344E0F;
        padding: 16px;

        p {
            color: #484848;
            font-weight: 500;
        }

        .note {
            font-style: italic;
            font-weight: 400;
        }

        label {
            font-weight: 500;
            font-size: 18px;
            margin-bottom: 5px;
        }

        input,
        .form-controlphone {
            border-radius: 4px;
            background-color: white;
            border: 1px solid #88AE408C;
            .react-tel-input .form-control {
                height: 42px !important;
                border-radius: 0px !important;
                width: 100% !important; 
                background: none !important;
                border: 1px solid rgba(75, 115, 51, 0.20) !important;
              }
        }
    }

    .permission {
        h3 {
            font-size: 18px;
            font-weight: 600;
        }
    }

}

.subadmincont {
    .css-13cymwt-control{
        background-color: transparent;
        border: 0;
    }
    .css-13cymwt-contro:focus{
        background-color: transparent;
    }
    .subbtngrop {
        margin: auto;
        cursor: pointer;
        // display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        // height: 37px;
        width: 160px;
        // padding: 0px 10px;
    }

    .invitExpire {
        border: 1px solid rgba(255, 65, 65, 0.25);
        background-color: rgba(255, 226, 226, 1);
        color: black;
    }

    .blockbtn {
        border: 1px solid rgba(245, 108, 137, 0.5008);
        background-color: rgba(240, 80, 110, 0.12);
        color: rgb(0, 0, 0);
    }

    .unblockbtn {
        border: 1px solid rgba(23, 205, 93, 0.501);
        background-color: rgba(78, 195, 98, 0.12);
    }

    .invitSent {
        border: 1px solid rgba(17, 153, 38, 0.25);
        background-color: rgba(235, 255, 238, 1);
    }
    select{
        option{
            background-color: white;
            border: 1px solid #264503;
        }
    }
}
.filterBody1{
    right: 0;
    top: 60px;
    position: absolute;
    padding: 20px;
    width: fit-content;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    h6{
        font-size: 16px;
        color: #24363A;
    }
}
.success{
    border: 1px solid #B4E5BC;
    background-color: #EBFFEE;
    border-radius: 4px;
    color: black;
    padding: 12px 18px;
}
.failed{
    border: 1px solid #eb4427;
    background-color: #501d177a;
    border-radius: 4px;
    color: rgb(255, 255, 255);
    padding: 12px 18px;
}


.deleteSubAdmin{
   button{
    background-color:#FF4141;
    color: white;
    padding: 7px 16px;
    border: 0;
   }
}