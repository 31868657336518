.viewProfile {
  .spending {
    color: #7fb768;
    padding: 8px 10px;
    background-color: white;
    border: 1px solid #88ae4024;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    line-height: 19.6px;

    span {
      margin-left: 10px;
      font-size: 14px;
      font-weight: 700;
      line-height: 19.6px;
      letter-spacing: -0.01em;
      color: #000000;
    }
  }

  .referralCode,
  .redflag {
    background-color: rgb(255, 215, 66, 22%);
    padding: 8px 10px;
    border: 1px solid #ffd742;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    line-height: 19.6px;
    color: #264503;

    span {
      color: #264503;
      font-weight: 400;
    }
  }

  .redflag {
    background-color: rgb(231, 38, 38);
    color: white;

    span {
      color: white;
    }
  }

  .statcont {
    // border: 2px solid #d9e3d9;
    border-radius: 8px;
    background-color: white;
    // margin-top: 20px;

    .booster {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      width: fit-content;
      border-radius: 6px;
      padding: 17px;
      border: 2px solid #88ae4026;
      display: flex;
      align-items: center;

      .dataShowtoogle {
        padding: 8px;
        border-radius: 8px;
        background-color: white;
        position: absolute;
        box-shadow: 0px 0px 4px gray;
        right: -160px;
        z-index: 10;
        top: -14px;
      }
      .dataShowtoogle2 {
        padding: 8px;
        border-radius: 8px;
        background-color: white;
        position: absolute;
        box-shadow: 0px 0px 4px gray;
        right: -130px;
        z-index: 10;
        top: -14px;
      }

      h2 {
        font-size: 14px;
        position: absolute;
        top: -12px;
        left: 20px;
        background-color: white;
        color: #000000;
        font-weight: 600;
      }

      h4 {
        color: #7fb768;
        font-size: 14.22px;
        font-weight: 600;
        line-height: 17.11px;
        display: flex;
        align-items: center;

        span {
          padding: 3px;
          margin-left: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: fit-content;
          height: 25px;
          border-radius: 2px;
          background-color: #7fb768;
          color: white;
        }
      }

      h3 {
        color: #7fb768;
        font-size: 15.22px;
        font-weight: 600;
        line-height: 17.11px;
        display: flex;
        align-items: center;
        color: #1a5664;

        span {
          color: #1a5664;
        }
      }
    }

    .otherdetail {
      border-radius: 4px;
      margin-right: 10px;
      font-weight: 700;
      font-size: 14px;
      width: 100%;
      height: 60px;
      border: 2px solid #88ae4026;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .award {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .imgcont {
        position: relative;
        width: 60px;
        height: 70px;
      }

      .level {
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #570094;
        font-family: 'Times New Roman', Times, serif;
        z-index: 9;
        position: absolute;
        left: 16px;
        right: 0;
        top: 24px;
        font-size: 18px;
        font-weight: 700;
      }

      .rank {
        background-color: #ffd742;
        color: #264503;
        padding: 10px 20px;
        border-radius: 4px;
        font-size: 16px;

        span {
          font-weight: 700;
        }
      }
    }
  }

  .personalData,
  .profile {
    border: 2px solid #d9e3d9;
    border-radius: 8px;
    background-color: white;
    padding: 12px 20px;
    height: 220px;
    @media (max-width: 1400px) {
      height: 250px;
    }

    .profileImg {
      width: fit-content;
      position: relative;

      .activeDot {
        position: absolute;
        width: 16px;
        height: 16px;
        background-color: #88ae40;
        border: 2px solid #ffffff8c;
        top: 0;
        right: 0px;
        border-radius: 50%;
      }
    }

    h2 {
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
    }

    h3 {
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;

      strong {
        font-weight: 600;
      }
    }
  }

  .personalData {
    label {
      font-size: 16px;
      font-weight: 500;
    }

    .input {
      font-size: 14px;
      border-radius: 4px;
      margin-top: 5px;
      border: 1px solid #88ae4070;
      padding: 5px 14px;
      width: 100%;
      overflow: hidden;
    }

    .inputMiddle {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .profile {
    .details-card {
      padding: 8px 10px;
      background-color: #fafff5;
      border: 1px solid #7fb7681f;
      border-radius: 3px;
    }
  }

  .statbtn {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .cont {
      cursor: pointer;
      border: 1px solid #26450326;
      border-radius: 4px;
      width: fit-content;
      padding: 10px 16px;
      margin-right: 20px;

      p {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
}
.filterbtn {
  border-radius: 6px;
  padding: 6px 16px;
  border: 0;
  background-color: white;
  box-shadow: 0px 0px 4px rgb(189, 182, 182);
}
.otherfilterbody {
  z-index: 9;
  top: 40px;
  right: 6px;
  right: 0;
  position: absolute;
  width: fit-content;
  background-color: white;
  padding: 16px;
  border-radius: 12px;
  box-shadow: 4px 4px 18px #0000001f;
}
