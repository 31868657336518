.dailyEdit {
  input {
    width: 70px;
    border: none;
  }

  .dailyEditbtn {
    display: none;
  }
  &:hover .dailyEditbtn {
    display: block;
  }
  &:hover input {
    border: 1px solid black;
  }
  .cross,
  .check {
    margin-inline-start: 10px;
    width: 30px;
    height: 30px;
    border: 1px solid gray;
    border-radius: 4px;
  }
  .check {
    color: white;
    border: 1px solid #264503;
    background-color: #264503;
  }
}
.custom-dropdown-container {
  position: relative;
  .filtertbn {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 149px;
    padding: 6px 10px;
    margin-right: 30px;
    border: 1px solid #4a73331f;
    border-radius: 4px;
  }
  .custom-dropdown {
    padding: 12px;
    z-index: 9999;
    position: absolute;
    top: 40px;
    left: -55px;
    width: 210px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0px 16px rgb(216, 216, 216);
  }
  label {
    color: #181818;
    font-weight: 400;
  }

  .dropdown-item {
    cursor: pointer;
    padding: 7px 4px;
    color: #88ae40;
    font-weight: 500;
    border-bottom: 1px solid #d9e3d9;
  }
  .apply,
  .cancle {
    padding: 6px 14px;
    border: 1px solid gray;
    border-radius: 4px;
  }
  .apply {
    color: white;
    border: 1px solid #264503;
    background-color: #264503;
  }
  .selected {
    color: #478205;
    font-weight: 500;
  }
}
.detailsbox {
  background-color: #88ae4038;
  color: black;
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid #2645031f;
}

.statdaily {
  .cards {
    height: 130px;
    padding: 14px;
    display: flex;
    justify-content: space-between;
    // align-items: center;
    border: 2px solid #7fb76838;
    background-color: white;
    border-radius: 2px;
    @media (max-width: 576px) {
      height: 100%;
    }
    h2 {
      font-size: 20px;
      margin-bottom: 0;
      @media (max-width: 1100px) {
        font-size: 18px;
      }
      span {
        font-style: italic;
        font-weight: 400;
      }
    }
    h3 {
      padding-top: 6px;
      color: #264503;
      font-size: 20px;
    }
  }
}

.datepcikers {
  padding: 16px;
  background-color: white;
  box-shadow: 0px 0px 16px rgb(216, 216, 216);
  width: 250px;
  right: 210px;
  position: absolute;
  border-radius: 10px;
  .datepic {
    color: gray;
    margin-top: 10px;
    width: 200px;
    padding: 12px;
    height: 40px;
    border: 1px solid #264503;
    border-radius: 4px;
  }
  .react-daterange-picker__wrapper {
    padding: 20px 12px;
  }
  .react-daterange-picker__clear-button {
    display: none;
    background-color: rgb(202, 81, 81);
    margin-top: 10px;
    padding: 6px;
    border: none;
  }
  // .react-daterange-picker__inputGroup__day{
  //     ::placeholder{
  //         con
  //     }
  // }
  .react-daterange-picker__calendar-button {
    border: 2px solid rgb(136, 174, 64);
    background-color: white;
    margin-top: 20px;
    padding: 5px;
    position: relative;

    &svg {
      color: white;
    }
  }

  .react-daterange-picker__inputGroup {
    border: 1px solid #264503;
  }
  .react-daterange-picker__inputGroup__input {
    padding: 4px;
    border-radius: 10px;
    border: 0px solid #264503;
    width: 65px !important;
    &:focus {
      outline: none !important;
      border: none !important;
    }
  }

  .react-datepicker {
    width: 100%;
  }
}

.walkingTime,
.cyclingTime {
  background-color: #88ae4038;
  width: 157px;
  height: 28pxpx;
  padding: 4px 8px 4px 8px;
  gap: 10px;
  border-radius: 4px;
  margin: auto;
}
.cyclingTime {
  background-color: #5b009738;
}
