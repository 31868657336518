.designScrollbarSide::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.designScrollbarSide::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ffffff;
  border-radius: 10px;
}

/* Handle */
.designScrollbarSide::-webkit-scrollbar-thumb {
  background: transparent ;
  border-radius: 10px;
}

/* Handle on hover */
.designScrollbarSide::-webkit-scrollbar-thumb:hover {
  background: transparent ;
}