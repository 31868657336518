.location-dropdown {
  position: absolute;
  background-color: #ffffff;
  border: 1px solid #00000045;
  z-index: 10;
  border-radius: 8px;
}
.location-dropdown div {
  padding: 0.3rem 0.2rem;
  border-bottom: 1px solid #00000045;
}
.login-input-style {
  color: #ffffff;
  background-color: rgba(247, 245, 245, 0.15);
  border-radius: 12px;
  border: none;
  outline: none;
  height: 45px;
}
.login-input-style::placeholder{
  color: #ffffff;
}
.login-input-style:focus {
  background-color: rgba(247, 245, 245, 0.15) !important;
  color: #ffffff !important;
  box-shadow: none;
  outline: none;
}
.login-bottom-text {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}
.redirect-signup {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  text-decoration: underline;
}
.redirect-signup:hover {
  color: #ffffff;
}
.login-forgot-passwoed {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: right;
  color: #ffffff;
  text-decoration: underline;
}
.backto-login {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
  gap: 10px;
}
.backto-login:hover {
  color: #ffffff;
}
.email-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.user-email {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}
.verify-input-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.otp-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.otp-input {
  width: 45px !important;
  height: 45px;
  color: #ffffff;
  background-color: rgba(247, 245, 245, 0.15);
  border-radius: 12px;
  border: none;
  outline: none;
}
.reset-password {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.changed-password-form {
  margin: auto;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-controlcont1{
  width: 600px;
  .input_box{
    width: 100%;
  }
}
.changed-password-container {
 width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.changed-label-name {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #264503;
  margin-bottom: 5px;
}

