.css-ahj2mt-MuiTypography-root{
    font-weight: 700 !important;
}
.transbtn{
    width: 32px;
    height: 32px;
    border-radius: 4px 0px 0px 0px;
    background-color: #FCE2E2;    
    display: flex;
    align-items: center;
    justify-content: center;
}
.MuiPaper-elevation{
border: 2px solid #5EAB0754;
}
.css-1086bdv-MuiPaper-root-MuiAccordion-root {
    box-shadow: 3px 2px 2px #5EAB0754;
}
ul{
    padding-left: 2rem;
    margin-bottom: 1rem;
}