.filterBody{
    left:300px;
    top: 60px;
    position: absolute;
    padding: 20px;
    width: fit-content;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    h6{
        font-size: 16px;
        color: #24363A;
    }
}
.calender{
    padding: 4px;
    border-radius: 4px;
    width: 250px;
    height: 40px;
    border: 1px solid #4B733333;
}