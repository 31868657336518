.levelmngt {
  .imgcont {
    margin: auto;
    position: relative;
    width: 35px;
    height: 45px;
    img{
    max-width: 100%;
    max-height: 100%;  
    }
  
  }

  .level {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #570094;
    font-family: "Times New Roman", Times, serif;
    z-index: 9;
    position: absolute;
    left: 8px;
    right: 0;
    top: 14px;
    font-size: 14px;
    font-weight: 700;
  }
}

.addlevel {
  label {
    font-weight: 500;
    margin-bottom: 10px;
  }
  .select-button{
    color:rgb(108 117 129);
  }
  .options-container{
    top: 40px;
    background-color: rgb(247, 247, 247);
    position: absolute;
    width: 100%;
    border: 1px solid #00000038;
    .option-button{
      color: rgb(108 117 129);;
      padding: 6px 10px;
      border-bottom: 1px solid #00000038;
    }
  }
  .redwardTypeselect {
    padding: 8px 0px;
    border: 0;
    width: 100%;
    height: 100%;
    &:focus {
      border: none;
      outline: none;
    }
  }
  .levelHistiry {
    h3{
      @media(max-width:992px){
        font-size: 20px;
      }
    }
    button,.editbtn {
      background-color: #88ae40;
      padding: 7px 14px;
      border-radius: 4px;
      cursor: pointer;
      border: none;
      color: white;

    }
    .levelHistorycont {
      background-color: white;
      border: 1px solid #00000038;
      border-radius: 4px;
      flex-wrap: wrap;
      label {
        font-size: 16px;
        font-weight: 500;
      }
      .inpt {
        display: flex;
        align-items: center;
        width: 194px;
        height: 50px;
        padding: 0px 10px 0px 10px;
        border-radius: 4px 0px 0px 0px;
        border: 1px 0px 0px 0px;
        border: 1px solid #4B733333;
      }
    }
  }
}
