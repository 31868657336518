.profile-form {
  width: 100% !important;
}
label{
  color: #264503;
  white-space: nowrap;
}
.profile-show {
  position: relative;
  display: inline-block;
}
.profile-change-icon {
  position: absolute;
  bottom: 15px;
  right: 5px;
  height: 35px;
  width: 35px;
  border-radius: 20px;
  cursor: pointer;
  background-color: #21afe6;
  display: flex;
  align-items: center;
  justify-content: center;
}
.propfilelabel{
  /* padding-left: 20px; */
  margin-bottom: 20px;
  font-family: Poppins;
font-size: 15px;
font-weight: 400;
line-height: 16.5px;
/* opacity: 50%; */

}
